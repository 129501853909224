import React from 'react';
import './App.css';
const { version } = require('../package.json');

const logo = process.env.PUBLIC_URL + '/star-logo-1k-white.png';

function App() {
  return (
    <div className="App">
      <header className="App-header">
      <h1>SkillRangers v{version}</h1>
        <img src={logo} className="App-logo" alt="logo" />
      </header>
    </div>
  );
}

export default App;
